/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'Account',
  components: {
    Cell: Cell,
    Group: Group,
    Scroll: Scroll
  },
  data: function data() {
    return {
      acmoney: '0.00',
      //账户余额
      acaddo: '',
      //现金余额
      acsubo: '',
      //补贴余额
      acEacho: '',
      //份数余额
      setting: {},
      //设置
      list: [],
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false,
      initFlag: true,
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0
    };
  },
  computed: {},
  methods: {
    //获取消费详情
    getdetails: function getdetails() {},
    //点击查看明细详情
    showDetail: function showDetail() {
      this.$router.push('/SpendDetail');
    },
    // 下拉刷新
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true;
      this.list = [];
      this.pageIndex = 1;
      this.getAccountInfo();
      this.getAccountRecords();
    },
    // 上划加载更多
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1;

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getAccountRecords();
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    // 查询显示哪个模块
    getRefund: function getRefund() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Spend.getRefund().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.setting = data;
          var set = {
            acAddo: _this.acaddo,
            rechargeAmt: _this.setting.rechargeamt,
            refundAmt: _this.setting.refundamt,
            refundstart: _this.setting.refundstart,
            refundend: _this.setting.refundend
          };

          _this.$utils.Store.setItem('set', JSON.stringify(set));
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    //获取账户信息
    getAccountInfo: function getAccountInfo() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Home.getAccountInfo({
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this2.$utils.Tools.loadHide();

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.acmoney = data.acMoney;
          _this2.acaddo = data.acAddo;
          _this2.acsubo = data.acSubo;
          _this2.acEacho = data.acEacho;

          _this2.getRefund();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    //获取账户记录
    getAccountRecords: function getAccountRecords() {
      var _this3 = this;

      this.emptyShow = false;
      this.$api.Home.getAccountRecords({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);

          _this3.updateScroll(false);
        } else {
          var _this3$list;

          (_this3$list = _this3.list).push.apply(_this3$list, _toConsumableArray(data.records));

          _this3.pageIndex = data.pageIndex;
          _this3.totalPage = data.totalPage; // 数据为空

          if (_this3.list.length === 0) {
            _this3.emptyShow = true;
          }

          _this3.updateScroll(false);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    //跳转充值页面
    recharge: function recharge() {
      this.$router.push('/Recharge');
    },
    // 设定当前页面
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.getAccountInfo();
    this.getAccountRecords();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};